import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { SafeAreaView, StyleSheet, Alert, Text, View, Image, Dimensions, TouchableOpacity, Platform, Linking, ScrollView } from 'react-native'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { Color } from './../../utils/color';
import { Constants } from './../../utils/constants';
import featureJson from '../../data/feature.json';
import MobileHeader from './../../components/MobileHeader';
import MenuSettings from '../../components/MenuSettings';
import SideMenu from './../../components/SideMenu';
import { createStyles, maxWidth, minWidth } from 'react-native-media-queries';
import Label from './../../components/Label';
import * as Localization from 'expo-localization';
import { isValidSubscription, getFeature, getCategories } from '../../services/Feature';
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { WebView } from 'react-native-webview';
import Iframe from 'react-iframe'
import { Analytics } from '../../components/Analytics';
import { Icon, Card, IndexPath, Layout, Select, SelectItem, Button } from '@ui-kitten/components';
import { FlatGrid } from 'react-native-super-grid';

export default class Engage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isWide: Platform.select({
                web: true,
                default: false,
            }),
            userToken:'',
            myWidth: Platform.select({
                web: Dimensions.get("window").width - 150,
                default: Dimensions.get("window").width,
            }),
            isOpen: false,
            originalWidth: Dimensions.get("window").width,
            fullName: "",
            email:'',
            category_id:'',
            categories: [],
            arrEngage: [],
            openFrame: false,
            openFrameurl: "",
            org_id:''
        }
    }
    async componentWillMount() {

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');

        let categories = [];
        if (userToken == "null") {
            this.props.navigation.navigate('LoginScreen');
        } else {
            categories = await getCategories(userToken);
            this.getEngageList()
        }

        const fullName = await AsyncStorage.getItem('fullName');
        const email = await AsyncStorage.getItem('email');
        let category_id = await AsyncStorage.getItem('category_id');
        // category_id =  (category_id === "undefined" || typeof category_id !== 'string') ? '' : category_id;
        category_id =  (category_id === "undefined" || typeof category_id !== 'string') ? '' : '';
        this.setState({
            fullName: fullName,
            org_id: organizationID,
            userToken,
            categories,
            email,
            category_id   
        })
    }

    // async componentDidUpdate(){
    //     console.log('arrEngage', this.state.arrEngage)
    // }


    getEngageList = async () => {
        //temporary inserts for demo
        const insightRetailPriceObject = {
            "id": "11",
            "code": "RTPR",
            "category_id": "1",
            "sub_category_id": "1",
            "name": "Retail Pricing                    ",
            "navigation_name": "Retailcx                                ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "paid",
            "price": 2,
            "logo": "RetailCX.png",
            "description": "Review Cx products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "1",
                "name": "Insight                                 ",
                "description": "Commerce Cloud Einstein Commerce Insights reports capture shopper, order, and product data and display products that are most commonly purchased together."
            },
            "subcategory": {
                "id": "1",
                "name": "Research Insights                       ",
                "description": "Retail pricing"
            },
            "options": {},
            "status": true
        }

        const differentialPricingObject = {
            "id": "17",
            "code": "DFPR",
            "category_id": "1",
            "sub_category_id": "1",
            "name": "Differential Pricing                    ",
            "navigation_name": "DiffPrice                                ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "paid",
            "price": 2,
            "logo": "RetailCX.png",
            "description": "Review Cx products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "1",
                "name": "Insight                                 ",
                "description": "Commerce Cloud Einstein Commerce Insights reports capture shopper, order, and product data and display products that are most commonly purchased together."
            },
            "subcategory": {
                "id": "1",
                "name": "Research Insights                       ",
                "description": "Retail pricing"
            },
            "options": {},
            "status": true
        }

        //temp inserts for uploaders
        const priceDifferentiation = {
            "id": "12",
            "code": "PRDF",
            "category_id": "3",
            "sub_category_id": "3",
            "name": "Price Differentiation Uploader                                 ",
            "navigation_name": "PriceDiffUploader                                  ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "free",
            "price": 2,
            "logo": "PriceDiff.png",
            "description": "All about Products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "3",
                "name": "Tools                                   ",
                "description": "This category helps user to upload, and process your data"
            },
            "subcategory": {
                "id": "3",
                "name": "Individual affinity Identification      ",
                "description": "Affinity Connections"
            },
            "options": {},
            "status": true
        }

        const revenueForcasting = {
            "id": "13",
            "code": "RVFC",
            "category_id": "3",
            "sub_category_id": "3",
            "name": "Revenue Forecast Uploader                                 ",
            "navigation_name": "RevenueForecastDataUploader                                  ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "free",
            "price": 2,
            "logo": "RevForecast.png",
            "description": "All about Products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "3",
                "name": "Tools                                   ",
                "description": "This category helps user to upload, and process your data"
            },
            "subcategory": {
                "id": "3",
                "name": "Individual affinity Identification      ",
                "description": "Affinity Connections"
            },
            "options": {},
            "status": true
        }

        const customer360Uploader = {
            "id": "14",
            "code": "C360",
            "category_id": "3",
            "sub_category_id": "3",
            "name": "Customer360 Data Uploader                                 ",
            "navigation_name": "Customer360Uploader                                 ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "free",
            "price": 2,
            "logo": "Cus360.png",
            "description": "All about Products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "3",
                "name": "Tools                                   ",
                "description": "This category helps user to upload, and process your data"
            },
            "subcategory": {
                "id": "3",
                "name": "Individual affinity Identification      ",
                "description": "Affinity Connections"
            },
            "options": {},
            "status": true
        }

        const mbtiUploader = {
            "id": "15",
            "code": "MBTI",
            "category_id": "3",
            "sub_category_id": "3",
            "name": "MBTI Data Uploader                                 ",
            "navigation_name": "MBTIDataUploader                                 ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "free",
            "price": 2,
            "logo": "Mbti.png",
            "description": "All about Products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "3",
                "name": "Tools                                   ",
                "description": "This category helps user to upload, and process your data"
            },
            "subcategory": {
                "id": "3",
                "name": "Individual affinity Identification      ",
                "description": "Affinity Connections"
            },
            "options": {},
            "status": true
        }

        const genderPredictionUploader = {
            "id": "16",
            "code": "GRPR",
            "category_id": "3",
            "sub_category_id": "3",
            "name": "Name-Gender Prediction Uploader                                 ",
            "navigation_name": "GenderPridictionUploader                                 ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "free",
            "price": 2,
            "logo": "Prediction.png",
            "description": "All about Products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "3",
                "name": "Tools                                   ",
                "description": "This category helps user to upload, and process your data"
            },
            "subcategory": {
                "id": "3",
                "name": "Individual affinity Identification      ",
                "description": "Affinity Connections"
            },
            "options": {},
            "status": true
        }

        const transcriptionUploader = {
            "id": "16",
            "code": "TRSC",
            "category_id": "1", // insight
            "sub_category_id": "3",
            "name": "Transcription Uploader                                 ",
            "navigation_name": "TranscriptionUploader                                 ",
            "country_code": "US",
            "currency_code": "USD",
            "type": "free",
            "price": 2,
            "logo": "transcription.png",
            "description": "All about Products",
            "stripe_product_id": "null",
            "is_active": true,
            "category": {
                "id": "3",
                "name": "Insight                                   ",
                "description": "This category helps user to upload, and process your data"
            },
            "subcategory": {
                "id": "3",
                "name": "Individual affinity Identification      ",
                "description": "Affinity Connections"
            },
            "options": {},
            "status": true
        }

        const userToken = await AsyncStorage.getItem('userToken');
        const organizationID = await AsyncStorage.getItem('organizationID');
        const email = await AsyncStorage.getItem('email');
        try {
            const data = await getFeature(userToken, Localization.region, true); 
            let arrEngage = [];
            for (let index = 0; index < data.length; index++) {
                let item = data[index];
                item.status = await this.getStatus(item.code);
            }
            // data.push(insightRetailPriceObject, differentialPricingObject, priceDifferentiation, revenueForcasting, customer360Uploader, mbtiUploader, genderPredictionUploader, transcriptionUploader)
            data.push(transcriptionUploader)
            this.setState({arrEngage:data});
        } catch (error) {
            console.error("====== error =====", error);
        }
    }

    setFromCategoryFooter = (object) => {
        console.log(this.setState);
    } 

    goTo = (url, isOpenFrame, navigation_name) => {
        this.setState({
            openFrame: isOpenFrame
        })
        this.props.navigation.navigate(navigation_name.trim());
        return false;
    };
    
    _handleLayout = ({ nativeEvent }) => {
        const { width } = nativeEvent.layout;

        this.setState({
            originalWidth: width
        })
        if (width >= 767) {
            this.setState({
                myWidth: Platform.select({
                    web: width - 150,
                    default: Dimensions.get("window").width,
                })

            })
        } else {
            this.setState({
                myWidth: Platform.select({
                    web: width,
                    default: Dimensions.get("window").width,
                })
            })
        }
        this.setState(() => ({ isWide: width >= 767 }));

    };

    async getStatus(code) {
        const status =  await isValidSubscription({
            code, 
            org_id: this.state.org_id, 
            userToken: this.state.userToken,
            email: this.state.email
        });
        return status || false;
    }

    render() {
        var nameC = ""
        if (this.state.fullName != "" && this.state.fullName != null) {
            const allCharacters = this.state.fullName.split(' ').map((animal) => animal[0]).join('')
            nameC = allCharacters.substring(0, 2)
        }

        return (
            <View onLayout={this._handleLayout} style={styles.Container}>
                <Analytics />
                {this.state.originalWidth < 767 && <MobileHeader {...this.props} title="Engage" />}
                <View style={{ flexDirection: 'row', flex: 1, }}>
                    {this.state.originalWidth >= 767 && <View style={[styles.sideBerLeft, { display: (this.state.originalWidth < 767 ? 'none' : 'flex') }]}>
                        <SideMenu {...this.props} />
                    </View>}
                    <View style={[styles.containBox, { width: this.state.myWidth }]}>
                        {this.state.originalWidth >= 767 && <View style={[styles.logoDesktop, {
                            width: this.state.myWidth,
                            justifyContent: "center",
                            alignItems: 'center'
                        }]}>
                            <Label Barlow_SemiBold style={[styles.title, {
                                display: (this.state.originalWidth < 767 ? 'none' : 'flex'), textAlign: 'center',
                                alignSelf: 'center',
                            }]}>Engage</Label>
                            <TouchableOpacity onPress={() => {
                                this.setState({
                                    isOpen: true
                                })
                            }} style={{ flexDirection: 'row', justifyContent: "space-between", alignItems: "center", position: "absolute", right: 20, }}>
                                <View style={{ width: 1, height: 28, marginRight: 22, backgroundColor: "#EBEBF2" }}></View>
                                <Label style={styles.JohnTitle} Barlow_Medium>{this.state.fullName}</Label>
                                <Image style={{ height: 7, width: 11, marginLeft: 10, marginTop: 2, }} source={require("./../../assets/images/small_down.png")} />
                                {/* <Image style={{ height: 38, width: 38, marginLeft: 12, }} source={require("./../../assets/images/profile_img.png")} /> */}
                                <View style={{ height: 38, width: 38, marginLeft: 12, backgroundColor: Color.DarkGreen, borderRadius: 19, alignItems: "center", justifyContent: "center" }}>
                                    <Label style={[styles.JohnTitle, { color: 'white' }]} Barlow_Bold>{nameC.toUpperCase()}</Label>
                                </View>
                            </TouchableOpacity>
                        </View>}
                        <MenuSettings 
                            {...this.props} 
                            isOpen={this.state.isOpen} 
                            myWidth={this.state.myWidth} 
                            onClick={() =>this.setState({ isOpen: false})} 
                        />

                        {this.state.openFrame === true && <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.setState({ openFrame: false })}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange }}>Back</Button>
                        </View>}

                        <ScrollView showsVerticalScrollIndicator={false} showsHorizontalScrollIndicator={false} scrollEnabled={!this.state.openFrame}>

                            {!this.state.openFrame && <View style={{
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                width: "100%",
                                alignItems: (this.state.originalWidth < 767 ? "center" : 'flex-start'),
                                justifyContent: (this.state.originalWidth < 767 ? "flex-start" : 'flex-start'),
                                paddingTop: 2,
                                paddingBottom: 10,
                                paddingLeft: (this.state.originalWidth < 767 ? 0 : 10)
                            }}>

                            {this.state.category_id === '' && 
                                <FlatGrid
                                    itemDimension={300}
                                    data={this.state.categories}
                                    spacing={10}
                                    renderItem={({ item }) => (
                                        <Card style={{margin: 20,
                                            width: 300, 
                                            borderRadius: 10, shadowRadius: 10, shadowColor: 'grey', shadowOpacity: 5}}>
                                            <Card onClick={ async () =>  { 
                                                    this.setState({category_id: item.id});
                                                    await AsyncStorage.setItem('category_id', item.id);
                                                }
                                            }
                                                style={{ marginBottom: 10 }}
                                                header={<CategoryHeader image={item.name} />}
                                                >
                                                <Label ml={40} mr={3} sigleLine={false} style={{ alignSelf: 'center', textAlign: "center" }} font15Large Barlow_SemiBold color={Color.DarkGreen} >{item.name}</Label>
                                            </Card>
                                            <CategoryFooter category_id={item.id} 
                                                menus={this.state.arrEngage}
                                                goTo={this.goTo} 
                                                setFromCategoryFooter={this.setFromCategoryFooter}
                                                {...this.props}
                                                />
                                        </Card>    
                                    )}
                                />

                            }

                        {/* secondary cards */}
                            {this.state.category_id !== '' && 
                                this.state.categories.filter(info => info.id === this.state.category_id).map((category) => {
                                return (
                                    <Card 
                                    disabled
                                    style={{margin: 20, width:'90%',
                                        borderRadius: 10, shadowRadius: 10, shadowColor: 'grey', shadowOpacity: 5}}
                                        header={<Label>{category.name}</Label>}
                                        footer={
                                            <View style={{margin:15, width: 100}}>
                            <Button size={'tiny'} 
                            onPress={() => this.setState({category_id:''})}
                            style={{backgroundColor: Color.Orange, borderColor: Color.Orange, width:100 }}>Back</Button>
                        </View>
                                        }>
                                        <FlatGrid
                                            itemDimension={200}
                                            data={this.state.arrEngage.filter(section => section.category_id === category.id )}
                                            spacing={15}
                                            renderItem={({ item }) => (
                                                <Card 
                                                    onClick={() => item.status === true ? 
                                                        this.goTo(item.options.url, item.options.is_iframe, item.navigation_name.trim()) : {}}
                                                    style={{margin: 20,
                                                        width: 200, 
                                                        height: 200,
                                                        borderRadius: 10, shadowRadius: 10, shadowColor: 'grey', shadowOpacity: 5}}
                                                        status = { item.status === true ? 'success' : 'danger'}
                                                        header={<Header image={item.logo} />} 
                                                        footer={<Footer status={item.status} /> }>
                                                        {/* centering doesnt work because json text contains extra spaces hence string is trimmed below */}
                                                        <Label sigleLine={false} numberOfLines={2} style={{minHeight: 34, alignSelf: 'center', textAlign: "center" }} font15Large Barlow_SemiBold color={Color.DarkGreen} >{item.name.trim()}</Label>
                                                    </Card>
                                            )}
                                        />                                  
                                    </Card>
                                )
                            })}
                            </View>}

                            {this.state.openFrame && <View style={{
                                width: "100%",
                                paddingTop: 0,
                                paddingBottom: 10,
                                paddingLeft: (this.state.originalWidth < 767 ? 0 : 0),
                                height: (this.state.originalWidth < 767 ? Dimensions.get("window").height - 120 : Dimensions.get("window").height - 145)
                            }}>


                                <View style={{
                                    width: this.state.myWidth,
                                    height: (this.state.originalWidth < 767 ? '100%' : Dimensions.get("window").height - 145),
                                    flexDirection: "column"
                                }}>

                                    {/* height={(this.state.originalWidth < 767 ? Dimensions.get("window").height - 120 : Dimensions.get("window").height - 150)} */}

                                    {(Platform.OS !== 'ios' && Platform.OS != 'android') && <Iframe
                                        onload="setInterval(document.getElementById('testFrame').contentWindow.location.reload(), 100);"
                                        url={this.state.openFrameurl}
                                        position="fixed"
                                        width={this.state.myWidth}
                                        id="myId"
                                        className="myClassname"
                                        height={"100%"}
                                        styles={{ marginTop: '80px' }} />}

                                    {(Platform.OS == 'ios' || Platform.OS == 'android') && <WebView
                                        useWebKit={true}
                                        javaScriptEnabled={true}
                                        source={{ uri: this.state.openFrameurl }}
                                        style={{ width: '100%', height: '100%' }}
                                        startInLoadingState={true}
                                    />}

                                </View>
                            </View>}

                        </ScrollView>

                    </View>

                </View>
            </View >

        )
    }
}

const Header = (props) => {
    try {
        return <View style={{alignItems:'center', padding: 10}}>
        <Image 
            style={{ height: 60, width: 60, marginLeft: 10, marginTop: 10, }} 
            source={require(`./../../assets/images/${props.image}`)} />  
        </View>
    } catch (error) {
        return <View style={{alignItems:'center', padding: 10}}>
        <Image 
        style={{ height: 100, width: 100, marginLeft: 10, marginTop: 10, }} 
        source={require(`./../../assets/images/financial.png`)} />  
        </View>
    }
}

const CategoryHeader = (props) => {
    const image = props.image.trim().toLowerCase();
    try {
        return <View style={{alignItems:'center', padding: 10}}>
        <Image 
            style={{ height: 60, width: 60, marginLeft: 10, marginTop: 10, }} 
            source={require(`./../../assets/images/${image}.png`)} />  
        </View>
    } catch (error) {
        return <View style={{alignItems:'center', padding: 10}}>
        <Image 
        style={{ height: 100, width: 100, marginLeft: 10, marginTop: 10, }} 
        source={require(`./../../assets/images/financial.png`)} />  
        </View>
    }
}

const CategoryFooter = (props) => {
    const [selectedIndex, setSelectedIndex] = React.useState(new IndexPath(0));
    const options = props.menus.filter(section => section.category_id === props.category_id && section.status === true);
    const _openPage = async (item) => {    
        /*
        const goTo = (url, isOpenFrame, navigation_name) => {
            if (isOpenFrame !== true) {
                props.setFromCategoryFooter({
                    openFrame: isOpenFrame
                })
                props.navigation.navigate(navigation_name.trim());
            } else {
                props.setFromCategoryFooter({
                    openFrame: isOpenFrame,
                    openFrameurl: url
                })
            }
            return false;
        };
        */
        await AsyncStorage.setItem('category_id', 'undefined');
        props.goTo(item.options.url, item.options.is_iframe, item.navigation_name.trim());
    }
    const Body = () => {
        return (
             <Select 
                placeholder={'Select Menu'}
                style={{border:0, padding:0, margin:0}}  
                size={'small'}
                status={'warning'}
                value = {'Select Menu'} 
                selectedIndex={selectedIndex}
                onSelect={index => _openPage(options[index.row])}>  
                    { options.map(item =>
                            <SelectItem title={item.name} />     
                    )}
            </Select>
        )
    }

    return (
        <View style={{border:0, padding:0, margin:0}}>
            <Body />
        </View>
    )
    
}

const Footer = (props) => {
    return props.status === true ?
        <View style={{alignItems:'flex-end', padding: 10}}>
            <Icon name={'star'} fill='#005961' style={{width: 20, height: 20 }} />
        </View> : 
        <View style={{alignItems:'flex-end', padding: 10}}>
            <Icon name={'slash'} fill='#005961' style={{width: 20, height: 20 }} />
        </View>

}

Engage.navigationOptions = {
    header: null,
};
const base = StyleSheet.create({
    Container: {
        flex: 1,
        backgroundColor: Color.WhiteLight,
    },
    logoDesktop: {
        backgroundColor: Color.White,
        height: 60,
        borderBottomWidth: 1,
        borderColor: "#005057",
    },
    title: {
        fontSize: 30,
        color: Color.DarkGreen,
        marginTop: 0,
        marginBottom: 0,
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        //width: Dimensions.get("window").width - 150,
    },
    profileDropdown: {
        backgroundColor: 'white',
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.08,
        shadowRadius: 8,
        elevation: 1,
        width: 155,
        position: 'absolute',
        right: 20,
        top: 80,
        borderBottomLeftRadius: 5,
        borderBottomRightRadius: 5
    },
    JohnTitle: {
        fontSize: 14,
        color: "#4D4F5C"
    },

    boxIcon: {
        height: 62,
        width: 62,
    },
    sideBerLeft: {
        width: 230,
        flex: 1,
    },
    containBox: {
        width: Dimensions.get("window").width - 150,
    },
    boxColumn: {
        backgroundColor: Color.White,
        marginLeft: 10,
        marginRight: 10,
        marginTop: 10,
        marginBottom: 10,
        borderRadius: 5,
        shadowColor: '#000',
        shadowOffset: { width: 0, height: 2 },
        shadowOpacity: 0.4,
        shadowRadius: 5,
        elevation: 1,
        alignItems: 'center',
        justifyContent: "center"
    }



});
const styles = createStyles(
    base,
    maxWidth(991, {
        title: {
            fontSize: 36,
            color: Color.DarkGreen,
            textAlign: 'left',
            marginLeft: 10,
            width: '100%',
            marginTop: 20,
        },


        boxIcon: {
            height: 62,
            width: 62,
        },
    }),
    maxWidth(767, {
        Container: {
            flex: 1,
            backgroundColor: Color.WhiteLight,
        },
        containBox: {
            //width: Dimensions.get("window").width,
        },

        boxIcon: {
            height: 62,
            width: 62,
        },
        containBox: {
            width: '100%',
        }

    }),
    minWidth(768, {
        sideBerLeft: {
            height: '100vh'
        },
    })
);





