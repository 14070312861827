import React from 'react';
import { Platform, Image, Text, Dimensions } from 'react-native';
import { createStackNavigator, createBottomTabNavigator, createDrawerNavigator, createSwitchNavigator } from 'react-navigation';
import Label from "./../components/Label"

// import TabBarIcon from '../components/TabBarIcon';
import HomeScreen from '../screens/HomeScreen';
// import LinksScreen from '../screens/LinksScreen';
// import SettingsScreen from '../screens/SettingsScreen';

// const config = Platform.select({
//     web: { headerMode: 'screen' },
//     default: {},
// });


import InformationRequest from "../screens/Tabs/InformationRequest";
import AddInformationRequest from "../screens/Tabs/AddInformationRequest";

import LoginScreen from "../screens/LoginScreen"
import DashboardScreen from "../screens/Tabs/Dashboard"
import EngageScreen from "../screens/Tabs/Engage"
import OrganizationData from "../screens/Tabs/OrganizationData"
import AuthLoadingScreen from "../screens/AuthLoadingScreen"
import UploaderScreen from '../screens/UploaderScreen';

import NotificationScreen from "../screens/Tabs/Notification"
import FinancialScreen from "../screens/Tabs/Financial"
import ProductScreen from "../screens/Tabs/ProductScreen"

import UploadScreen from "../screens/UploadScreen"
import ProductDetailScreen from '../screens/ProductDetailScreen'
import RetailDataUploaderScreen from '../screens/RetailDataUploaderScreen'
import SubscriptionDataUploaderScreen from '../screens/SubscriptionDataUploaderScreen'
import RevenueForcastUploaderScreen from '../screens/RevenueForecastUploaderScreen';
import PriceDiffUploaderScreen from '../screens/PriceDiffUploaderScreen';
import Customer360UploaderScreen from '../screens/Customer360UploaderScreen';
import MBTIUploaderScreen from '../screens/MBTIUploaderScreen';
import GenderPredictionUploaderScreen from '../screens/GenderPredictionUploaderScreen';
import TranscriptionUploaderScreen from '../screens/TranscriptionUploaderScreen';
import ContextDataScreen from '../screens/ContextDataScreen'
import CampaignDataScreen from '../screens/CampaignDataScreen'
import Campaign from '../screens/CampaignSourceScreen'
import InvoiceScreen from '../screens/InvoiceScreen'
import LeadcxScreen from '../screens/LeadcxScreen'
import ReviewCxScreen from '../screens/ReviewCxScreen';
import RetailCxScreen from '../screens/RetailCxScreen';
import DifferentialPricingScreen from '../screens/DifferentialPricingScreen';
import IntegrationScreen from '../screens/IntegrationScreen';
import { Notifications } from 'expo';
import { Color } from '../utils/color';

import ForgotPassword from "../screens/ForgotPassword"
import ResetPassword from "../screens/ResetPassword"



import OrganizationScreen from "../screens/Tabs/Organization/Organization"
import InviteUsersScreen from "../screens/Tabs/Organization/InviteUsers"
import { Chatbot } from '../screens/Tabs/Organization/Chat';
import {aufinance} from '../screens/Tabs/Organization/aufinance';
import {yesbankChat} from '../screens/Tabs/Organization/yesbankChat';
import {aufadmin} from '../screens/Tabs/Organization/aufadmin';
import {yesbankAdmin} from '../screens/Tabs/Organization/yesbankAdmin';
import {titanChat} from '../screens/Tabs/Organization/titanChat';
import {titanAdmin} from '../screens/Tabs/Organization/titanAdmin';
import {cusscoChat} from '../screens/Tabs/Organization/cusscoChat';
import {cusscoAdmin} from '../screens/Tabs/Organization/cusscoAdmin';
import {shoppsterChat} from '../screens/Tabs/Organization/shoppsterChat';
import {mediAssistChat} from '../screens/Tabs/Organization/mediAssistChat';
import {voiceDemo2} from '../screens/Tabs/Organization/voiceDemo2';
import {voiceDemo3} from '../screens/Tabs/Organization/voiceDemo3';
import {voiceTextDemo} from '../screens/Tabs/Organization/voiceTextDemo';
import {britamDemo} from '../screens/Tabs/Organization/britamDemo';
import {britamHealth} from '../screens/Tabs/Organization/britamHealth';
import {kraChat} from '../screens/Tabs/Organization/kraChat';
import {kraAdmin} from '../screens/Tabs/Organization/kraAdmin';
import {aufinanceCC} from '../screens/Tabs/Organization/aufinanceCC';
import {federalbank} from '../screens/Tabs/Organization/federalbank';
import {federalbankAdmin} from '../screens/Tabs/Organization/federalbankAdmin';
import {fedserv} from '../screens/Tabs/Organization/fedserv';
import {fedservAdmin} from '../screens/Tabs/Organization/fedservAdmin';
import {kqTest} from '../screens/Tabs/Organization/kqTest';
import {iconicDemo} from '../screens/Tabs/Organization/iconicDemo';
import {homeFinance} from '../screens/Tabs/Organization/homeFinance';
import {homeFinanceAdmin} from '../screens/Tabs/Organization/homeFinanceAdmin';
import {bankingChat} from '../screens/Tabs/Organization/bankingChat';
import {qrcheck} from '../screens/Tabs/Organization/qrcheck';
import {extra1} from '../screens/Tabs/Organization/extra1';
import {ixigo} from '../screens/Tabs/Organization/ixigo';
import {croma} from '../screens/Tabs/Organization/Croma';
import ProfileScreen from "../screens/Tabs/Organization/Profile"
import PurchaseScreen from "../screens/Tabs/Organization/Purchase"
import FailedPurchaseScreen from "../screens/Tabs/Organization/FailedPurchase"
import APISettingsScreen from '../screens/Tabs/Organization/APISettings';
import AdminSettingsScreen from '../screens/Tabs/Organization/AdminSettings';
import SideMenu from './../components/SideMenu';
import SideMenuMobile from '../components/SideMenuMobile';
import SideMenuMobileWeb from '../components/SideMenuMobileWeb';



const config = Platform.select({
    web: { headerMode: 'screen' },
    default: {},
});

const configChatbot = Platform.select({
    web: { headerMode:'none' },
    default: {},
});

// Organization
const InviteUsersStack = createStackNavigator(
    {
        InviteUsers: InviteUsersScreen,
    },
    config
);


InviteUsersStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color, justifyContent: 'center', alignItems: 'center', }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Invite Users
            </Label>
        );
    },
    gesturesEnabled: false,
    header: null

};

InviteUsersStack.path = '';

//P
const ChatbotStack = createStackNavigator(
    {
        chatbot: Chatbot,
    },
    configChatbot
);


ChatbotStack.navigationOptions = {
    gesturesEnabled: false,
    header: null

};

ChatbotStack.path = '';


const ProfileStack = createStackNavigator(
    {
        Profile: ProfileScreen,
    },
    config
);


ProfileStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color, justifyContent: 'center', alignItems: 'center', }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Invite Users
            </Label>
        );
    },
    gesturesEnabled: false,
    header: null

};

ProfileStack.path = '';


const PurchaseStack = createStackNavigator(
    {
        Purchase: PurchaseScreen,
    },
    config
);


PurchaseStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color, justifyContent: 'center', alignItems: 'center', }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Invite Users
            </Label>
        );
    },
    gesturesEnabled: false,
    header: null

};

PurchaseStack.path = '';

//APISettingsScreen
const APISettingsStack = createStackNavigator(
    {
        APISettings: APISettingsScreen,
    },
    config
);


APISettingsStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color, justifyContent: 'center', alignItems: 'center', }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                API Settings
            </Label>
        );
    },
    gesturesEnabled: false,
    header: null

};

APISettingsStack.path = '';


//AdminSettingsScreen
const AdminSettingsStack = createStackNavigator(
    {
        AdminSettings: AdminSettingsScreen,
    },
    config
);


AdminSettingsStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color, justifyContent: 'center', alignItems: 'center', }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Admin Settings
            </Label>
        );
    },
    gesturesEnabled: false,
    header: null

};

AdminSettingsStack.path = '';




const FailedPurchaseStack = createStackNavigator(
    {
        FailedPurchase: FailedPurchaseScreen,
    },
    config
);

FailedPurchaseStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color, justifyContent: 'center', alignItems: 'center', }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Invite Users
            </Label>
        );
    },
    gesturesEnabled: false,
    header: null

};

FailedPurchaseStack.path = '';

// Dashboard
const DashboardStack = createStackNavigator(
    {
        Dashboard: DashboardScreen,
    },
    config
);

DashboardStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/dashboard.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Dashboard
            </Label>
        );
    },
    gesturesEnabled: false

};

DashboardStack.path = '';

// Engage (-Stack primarily used for mobile bottom tab navigation)
const EngageStack = createStackNavigator({
        Engage: EngageScreen,
        InformationRequest: InformationRequest,
        AddInformationRequest: AddInformationRequest 
    },
    config
);

EngageStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 15, height: 21, tintColor: color }}
                source={require('./../assets/images/engage.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Engage
            </Label>
        );
    },
    gesturesEnabled: false

};

EngageStack.path = '';

// Organization Data
const OrganizationDataStack = createStackNavigator(
    {
        OrganizationData: OrganizationData,
    },
    config
);

OrganizationDataStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 15, height: 21, tintColor: color }}
                source={require('./../assets/images/Organization_Selected.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Organization Data
            </Label>
        );
    },
    gesturesEnabled: false

};

OrganizationDataStack.path = '';


// Notification
const NotificationStack = createStackNavigator(
    {
        Notification: NotificationScreen,
    },
    config
);

NotificationStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 19, height: 21, tintColor: color }}
                source={require('./../assets/images/notification.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Customer Updates
            </Label>
        );
    },
    gesturesEnabled: false

};

NotificationStack.path = '';

// Financial
const FinancialStack = createStackNavigator(
    {
        Financial: FinancialScreen,
    },
    config
);

FinancialStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Financial
            </Label>
        );
    },
    gesturesEnabled: false

};

FinancialStack.path = '';

// Product Stack
const ProductStack = createStackNavigator(
    {
        Product: ProductScreen,
    },
    config
);

ProductStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Product
            </Label>
        );
    },
    gesturesEnabled: false

};

ProductStack.path = '';

// Upload 
const UploadStack = createStackNavigator(
    {
        Upload: UploadScreen,
    },
    config
);

UploadStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Upload Files
            </Label>
        );
    },
    gesturesEnabled: false

};

UploadStack.path = '';

// Retail Data Uploader
const RetailDataUploaderStack = createStackNavigator(
    {
        RetailDataUploader: RetailDataUploaderScreen,
    },
    config
);

RetailDataUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Retail Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

RetailDataUploaderStack.path = '';

// Subscription Data Uploader
const SubscriptionDataUploaderStack = createStackNavigator(
    {
        SubscriptionDataUploader: SubscriptionDataUploaderScreen,
    },
    config
);

SubscriptionDataUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Subscription Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

SubscriptionDataUploaderStack.path = '';

// Revenue Forecast Data Uploader
const RevenueForcastDataUploaderStack = createStackNavigator(
    {
        RevenueForecastDataUploader: RevenueForcastUploaderScreen,
    },
    config
);

RevenueForcastDataUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Revenue Forecast Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

RevenueForcastDataUploaderStack.path = '';

// Price Differentiation Data Uploader
const PriceDiffUploaderStack = createStackNavigator(
    {
        PriceDiffUploader: PriceDiffUploaderScreen,
    },
    config
);

PriceDiffUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Price Differentiation Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

PriceDiffUploaderStack.path = '';

// Customer 360 Data Uploader
const Customer360DataUploaderStack = createStackNavigator(
    {
        Customer360Uploader: Customer360UploaderScreen,
    },
    config
);

Customer360DataUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Customer360 Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

Customer360DataUploaderStack.path = '';

// MBTI Data Uploader
const MBTIDataUploaderStack = createStackNavigator(
    {
        MBTIDataUploader: MBTIUploaderScreen,
    },
    config
);

MBTIDataUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                MBTI Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

MBTIDataUploaderStack.path = '';

// gender Prediction Data Uploader
const GenderPredictionUploaderStack = createStackNavigator(
    {
        GenderPridictionUploader: GenderPredictionUploaderScreen,
    },
    config
);

GenderPredictionUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Gender Prediction Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

GenderPredictionUploaderStack.path = '';

// transcription Data Uploader
const TranscriptionUploaderStack = createStackNavigator(
    {
        TranscriptionUploader: TranscriptionUploaderScreen,
    },
    config
);

TranscriptionUploaderStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Transcription Data Uploader
            </Label>
        );
    },
    gesturesEnabled: false

};

TranscriptionUploaderStack.path = '';

// Product Detail 
const ProductDetailStack = createStackNavigator(
    {
        ProductDetail: ProductDetailScreen,
    },
    config
);

ProductDetailStack.navigationOptions = {
    tabBarIcon: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Image
                style={{ width: 21, height: 21, tintColor: color }}
                source={require('./../assets/images/financial.png')}
            />
        )
    },
    tabBarLabel: ({ focused }) => {
        let color = !focused ? Color.DISBLE_TAB : Color.DarkGreen;
        return (
            <Label x10small color={color} style={{ alignSelf: 'center', marginBottom: (Platform.OS != 'ios' ? 5 : 0) }}>
                Product Details
            </Label>
        );
    },
    gesturesEnabled: false

};

ProductDetailStack.path = '';

// Bottom Stack Navigation for mobile
const TabNavigation = createBottomTabNavigator({
    Dashboard: DashboardStack,
    Notification: NotificationStack,
    Engage: EngageStack,
    Financial: FinancialStack,
    Product: ProductStack,
    Upload: UploadStack,
    ProductDetail:ProductDetailStack
});

TabNavigation.path = '';

TabNavigation.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

// ? (Test on Mobile) Native Drawer navigator contains tab navigation
const DrawerNavigator = createDrawerNavigator(
    {
        SideMenu1: TabNavigation,
        OrganizationData: OrganizationData,
        InviteUsers: InviteUsersStack,
        chat: ChatbotStack,
        Profile:ProfileStack,
        Purchase:PurchaseStack,
        APISettings: APISettingsScreen,
        AdminSettings: AdminSettingsScreen,
        FailedPurchase: FailedPurchaseStack
    },
    {
        drawerPosition: "right",
        drawerWidth: 230,
        contentComponent: SideMenuMobile,
        drawerBackgroundColor: Color.TRANSPARENT,
        overlayColor: Color.TRANSPARENT,
        navigationOptions: {
            header: null,
            gesturesEnabled: false
        }
    }
);

//Both Drawer Navigations
const SideDrawerWeb = createSwitchNavigator(
    {
        Dashboard: DashboardScreen,
        OrganizationData: OrganizationData,
        InviteUsers: InviteUsersStack,
        chatbot: ChatbotStack,
        Notification: NotificationScreen,
        Engage: EngageScreen,
        FailedPurchase:FailedPurchaseScreen,
        Uploader: UploaderScreen,
        Financial: FinancialScreen,
        InformationRequest: InformationRequest,
        AddInformationRequest: AddInformationRequest,
        Product: ProductScreen,
        Upload: UploadScreen,
        RetailDataUploader: RetailDataUploaderScreen,
        SubscriptionDataUploader: SubscriptionDataUploaderScreen,
        RevenueForecastDataUploader: RevenueForcastUploaderScreen,
        PriceDiffUploader: PriceDiffUploaderScreen,
        Customer360Uploader: Customer360UploaderScreen,
        MBTIDataUploader: MBTIDataUploaderStack,
        GenderPridictionUploader: GenderPredictionUploaderScreen,
        TranscriptionUploader:TranscriptionUploaderScreen,
        ContextData: ContextDataScreen,
        CampaignData: CampaignDataScreen,
        Campaign,
        Invoice: InvoiceScreen,
        Leadcx: LeadcxScreen,
        Reviewcx: ReviewCxScreen,
        Retailcx: RetailCxScreen,
        DiffPrice: DifferentialPricingScreen,
        Profile: ProfileStack,
        Purchase: PurchaseStack,
        APISettings: APISettingsStack,
        AdminSettings: AdminSettingsStack,
        Integration: IntegrationScreen,
        ProductDetail: ProductDetailScreen
    },

);

SideDrawerWeb.navigationOptions = {
    header: null
};


// Main Drawer Navigation
const DrawerNavigatorWeb = createDrawerNavigator(
    
    //main left side drawer navigation
    {
        SideMenu1: SideDrawerWeb
    },
    
    //On narrowing the screen width, it should push out the left side drawer out of the view and right navigation should appear 
    //This is the right side 'pullable' drawer navigation
    {
        drawerPosition: "right", //originally right
        // drawerWidth: (Dimensions.get('window').width > 768 ? 0 : 200),
        drawerWidth: 230,
        contentComponent: SideMenuMobileWeb,
        overlayColor: Color.TRANSPARENT,
        drawerBackgroundColor: 'transparent',
        navigationOptions: {
            header: null,
            gesturesEnabled: false
        }
    }
);


const LoginNavigation = createStackNavigator(
    {
        LoginScreen: LoginScreen,
        chatbot: Chatbot,
        aufinance: aufinance,
        aufinanceCC: aufinanceCC,
        federalbank:federalbank,
        federalbankAdmin:federalbankAdmin,
        fedserv:fedserv,
        fedservAdmin:fedservAdmin,
        kqTest: kqTest,  //config removed from gcp
        yesbankChat: yesbankChat,
        titanChat: titanChat,
        cusscoChat: cusscoChat,
        kraChat:kraChat,
        kraAdmin:kraAdmin,
        vDemo2: voiceDemo2,
        vDemo3: voiceDemo3,
        voiceTextDemo: voiceTextDemo,
        bankingChat: bankingChat,
        qrcheck:qrcheck,
        extra1:extra1,
        ixigo:ixigo,
        croma: croma,
        britamDemo: britamDemo,
        britamHealth: britamHealth,
        iconicDemo: iconicDemo,
        homeFinance: homeFinance,
        homeFinanceAdmin: homeFinanceAdmin,
        shoppsterChat:shoppsterChat,
        mediAssistChat:mediAssistChat,
        aufadmin: aufadmin,
        yesbankAdmin:yesbankAdmin,
        titanAdmin: titanAdmin,
        cusscoAdmin: cusscoAdmin,
        ForgotPassword: ForgotPassword,
        ResetPassword: ResetPassword,
        Organization: OrganizationScreen
    },
    // config
    configChatbot
);

LoginNavigation.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

LoginNavigation.path = '';

// Loading::

const LoadingNavigation = createStackNavigator(
    {
        AuthLoadingScreen: AuthLoadingScreen,

    },
    config
);

LoadingNavigation.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

LoadingNavigation.path = '';



const AuthStack = createSwitchNavigator(
    {
        // InformationRequest1: InformationRequest1,
        AuthLoadingScreen: LoadingNavigation,
        Auth: LoginNavigation,
        App: Platform.select({
            web: DrawerNavigatorWeb,  //contains both left drawer and right drawer
            default: DrawerNavigator, //for native contains tab navigation 
        })

    },
    {
        initialRouteName: 'AuthLoadingScreen'
    },
    config,
);

AuthStack.navigationOptions = {
    header: null,
    gesturesEnabled: false
};

AuthStack.path = '';

export default AuthStack;
